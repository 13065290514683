import React from 'react';
import { Link } from 'react-router-dom';

import { HomeContainer, BlurbContainer, BlurbHeader, BlurbText, BlurbImg } from '../Components/Home.Styles';
import { AboutContainer, AboutImg, AboutDescContainer, AboutDescText, AboutDescHeader } from '../Components/About.Styles.js';

//test
import { blurb } from "../Assets/hometext.js";
import mikeImg from "../Assets/mikeImgs/homeImg1.jpg";
import mikeImg2 from "../Assets/mikeImgs/homeImg.jpg";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    AOS.init();
    return (

        <div>

        <HomeContainer>

                    <BlurbContainer data-aos="fade-up" data-aos-duration="600">
                            <BlurbHeader > 
                                {blurb.head}
                            </BlurbHeader>

                            <BlurbText>
                                {blurb.p1}
                            </BlurbText>

                            <BlurbText>
                                {blurb.p2}
                            </BlurbText>
                    </BlurbContainer>

                    <BlurbImg src={mikeImg} data-aos="fade-up" data-aos-duration="600" data-aos-delay="300"/>

        </HomeContainer>

        <AboutContainer>
                <AboutImg src={mikeImg2} data-aos="fade-right" data-aos-duration="600"data-aos-delay="100" />
                <AboutDescContainer data-aos="fade-left" data-aos-duration="600" data-aos-delay="200">

                    <AboutDescHeader>
                        {`// who am i?`}
                    </AboutDescHeader>

                    <AboutDescText>
                        i'm currently an undergrad computer science student at Boston University! i'm also a teaching
                        assistant for <Link to="https://www.bu.edu/academics/cas/courses/cas-cs-131/" className="textLink"> CS131,</Link> a class that teaches proof-writing on concepts like mathematical induction,
                        propositional logic, set theory, and combinatorics.
                    </AboutDescText>

                    <AboutDescText>
                        although i don't plan to develop websites as a career, i learned nearly all my front-end knowledge by working with my friend <Link to="https://github.com/eliameshie" className="textLink"> Eli</Link> during my time at <Link to="https://www.nynthlabs.com/" className="textLink"> Nynth Labs. </Link>
                    </AboutDescText>

                </AboutDescContainer>
        </AboutContainer>

        </div>

    )
}

export default Home;