import Styled from "styled-components";
import pattern from "../Assets/pattern1.svg";

export const ResumeContainer = Styled.div`
    background-color: ${props => props.theme.main.background};
    background-image: url(${pattern});
	background-size: contain;
	background-repeat: repeat;
    padding-bottom: 2rem;
    height: 92vh;
    justify-content: center;
    padding: 0 15vw;
    display: flex;
    margin-top: 8vh;
    width: 100vw;
`

export const ResumeViewer = Styled.img`
    height: 80%;
    margin: auto 5rem auto 10rem;
    box-shadow: -2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    position: relative;
`

export const ResumeDescContainer = Styled.div`
    padding: 2rem 3rem;
    background-color: ${props => props.theme.main.primary};
    width: 100%;
    margin: auto 10rem auto 0;
    height: auto;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
`

export const ResumeDescText = Styled.p`
    font-size: 1.25vw;
    margin-bottom: 1rem;
    line-height: 1.5vw;
    color: ${props => props.theme.main.text};
`

export const ResumeDescHeader = Styled.h1`
    font-size: 2.5vw;
    margin: 1rem auto 2rem;
    color: ${props => props.theme.main.text};
`