import React from 'react';
import { CS131Container, CS131embed, CS131DescContainer, CS131DescText, CS131DescHeader } from '../Components/CS131.Styles.js';
import CS131pdf from '../Assets/CS131notes.pdf'
import AOS from 'aos';
import 'aos/dist/aos.css';

const CS131 = () => {
	AOS.init();
	return (
		<CS131Container>
			<CS131DescContainer data-aos="fade-down" data-aos-duration="600">
				<CS131DescHeader>
					{`// resources!`}
				</CS131DescHeader>

				<CS131DescText>
					i love cs131! nevertheless, it can be a lot to handle. here are the notes i took for it as a freshman, annotated and translated for ease of reading. please note that
					these notes may not be comprehensive, and are only meant to be supplemental.
				</CS131DescText>
			</CS131DescContainer>

			<CS131embed src={CS131pdf} data-aos="fade-up" data-aos-duration="600" data-aos-delay="200"> </CS131embed>
		</CS131Container>
	)
}

export default CS131;