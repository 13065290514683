import Styled from "styled-components";
import pattern from "../Assets/pattern1.svg";

export const CS131Container = Styled.div`
    background-color: ${props => props.theme.main.background};
    background-image: url(${pattern});
	background-size: contain;
	background-repeat: repeat;
    height: 92vh;
    justify-content: center;
    padding: 5rem 15vw;
    display: flex;
    margin-top: 8vh;
    width: 100vw;
`

export const CS131DescContainer = Styled.div`
    padding: 2rem 3rem;
    background-color: ${props => props.theme.main.primary};
    width: auto;
    height: auto;
    margin: auto 10rem auto 0;
    box-shadow: -2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    position: relative;
`

export const CS131DescText = Styled.p`
    font-size: 1.25vw;
    margin-bottom: 1.5rem;
    line-height: 1.5vw;
    color: ${props => props.theme.main.text};
`

export const CS131DescHeader = Styled.h1`
    font-size: 2.5vw;
    margin: 1rem auto 2rem;
    color: ${props => props.theme.main.text};
`

export const CS131embed = Styled.iframe`
    height: 80%;
    width: 100%;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    margin: auto 0;
    user-drag: none;
`