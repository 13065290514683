import React from 'react';
import { HeaderContainer, HeaderTitle, HeaderSubtitle, LayoutButton, LayoutButtonLink } from "../Components/Layout.Styles.js";
import { Link } from 'react-router-dom';

const Header = () => {
	return (
		<HeaderContainer>
			<HeaderTitle>
				<Link to={"/"} className="mikeyHeader"> mike_lott </Link>
			</HeaderTitle>

			<HeaderSubtitle>
				aka maggle
			</HeaderSubtitle>

			<div className="layoutButtons">

				<LayoutButtonLink to={"/personal"}>
					<LayoutButton>
						personal
					</LayoutButton>
				</LayoutButtonLink>

				<LayoutButton className="dropdown">
					work/edu
					<div className="dropdownMenu">

						<div className="dropdownBuffer" />

						<div className="dropdownLink">
							<Link to={"/resume"}>
								<p> resume </p>
							</Link>
						</div>

						<div className="dropdownLink">
							<Link to={"/CS131"}>
								<p> CS131 </p>
							</Link>
						</div>


						<div className="dropdownLink">
							<Link to={"/projects"}>
								<p> projects </p>
							</Link>
						</div>

					</div>
				</LayoutButton>


				<LayoutButtonLink to={"/meta"}>
					<LayoutButton>
						this site
					</LayoutButton>
				</LayoutButtonLink>
			</div>
		</HeaderContainer>
	)
}

export default Header;