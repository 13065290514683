import Styled from 'styled-components';
import pattern from "../Assets/pattern1.svg";

export const PersonalContainer = Styled.div`
    background-color: ${props => props.theme.main.background};
    background-image: url(${pattern});
	background-size: contain;
	background-repeat: repeat;
    padding-bottom: 2rem;
    height: 92vh;
    justify-content: center;
    padding: 0vw 15vw;
    margin-top: 5vh;
    display: flex;
    width: 100vw;
`

export const PersonalImg = Styled.img`
    height: 25vw;
    box-shadow: -2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    margin: auto 0;
    user-drag: none;
`

export const PersonalDescContainer = Styled.div`
    padding: 2rem 3rem;
    background-color: ${props => props.theme.main.primary};
    width: 100%;
    height: auto;
    margin: auto 0 auto 10rem;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    position: relative;
`

export const PersonalDescText = Styled.p`
    font-size: 1.25vw;
    margin-bottom: 1.5rem;
    line-height: 1.5vw;
    color: ${props => props.theme.main.text};`

export const PersonalDescHeader = Styled.h1`
    font-size: 2.5vw;
    margin: 1rem auto 2rem;
    color: ${props => props.theme.main.text};
`