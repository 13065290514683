// The website's colorways.

export const theme = {
    main: {
        primary: "#FF7E47",
        secondary: "#274068",
        tertiary: "#000000",
        background: "#FFF1EB",
        text: "#FFF1EB",
    },
    reserve: {
        primary: "#274068",
        secondary: "#DB986B",
        tertiary: "#000000",
        background: "#F2F5F8",
        text: "#F2F5F8",
    }
}