import React from 'react';
import { ResumeContainer, ResumeViewer, ResumeDescContainer, ResumeDescHeader, ResumeDescText } from '../Components/Resume.Styles.js';
import resumePNG from '../Assets/resumeAssets/resume.png';
import resumePDF from '../Assets/resumeAssets/resume.pdf';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Resume = () => {
    AOS.init();
	return (
		<div>
			<ResumeContainer>
				<ResumeViewer src={resumePNG} data-aos="fade-up" data-aos-duration="600"></ResumeViewer >
				<ResumeDescContainer data-aos="fade-up" data-aos-duration="600" data-aos-delay="300">
					<ResumeDescHeader>
						{`// this is my resume.`}
					</ResumeDescHeader>
					<ResumeDescText>
						if you'd like, you can download a PDF by clicking <a href={resumePDF} className="textLink"> here. </a>
					</ResumeDescText>
				</ResumeDescContainer>
			</ResumeContainer>
		</div>
	)
}

export default Resume