import React from 'react';
import { Link } from 'react-router-dom';
import { MetaContainer, MetaImg, MetaDescContainer, MetaDescHeader, MetaDescText } from '../Components/Meta.Styles.js';
import { NewsContainer, NewsImg, NewsDescContainer, NewsDescText, NewsDescHeader } from '../Components/News.Styles.js';
import boston from '../Assets/metaAssets/boston.jpg';
import spongebob from '../Assets/metaAssets/spongebob.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Meta = () => {
	AOS.init();
	return (
		<div>

			<MetaContainer>
				<MetaDescContainer data-aos="fade-right" data-aos-duration="600" data-aos-delay="100">
					<MetaDescHeader>
						{`// how'd this get here?`}
					</MetaDescHeader>

					<MetaDescText>
						this was a project i decided to take on in march 2023 during spring break. immediately before building this website,
						along with internship season being in full swing, i started to catch an itch for applied CS. although i was overly ambitious in planning
						projects for break, i managed to come out with this!
					</MetaDescText>

					<MetaDescText>
						although i've built websites before, they were normally for companies like <Link to="https://nynthlabs.com/" className="textLink"> this one.</Link> thus, i took inspiration from <Link to="https://dcmaglione.com" className="textLink"> this website</Link> and <Link to="https://omid.gg" className="textLink"> this website.</Link>
					</MetaDescText>

				</MetaDescContainer>

				<MetaImg src={boston} data-aos="fade-left" data-aos-duration="600" data-aos-delay="300"/>
			</MetaContainer>

			<NewsContainer>
				<NewsImg src={spongebob} data-aos="fade-up" data-aos-duration="600" data-aos-delay="100"/>

				<NewsDescContainer data-aos="fade-down" data-aos-duration="600" data-aos-delay="300">
					<NewsDescHeader>
						{`// what next?`}
					</NewsDescHeader>

					<NewsDescText>
						this site will see continual updates over the course of my CS career. projects that i've been a part of will be showcased
						in the (hopefully not empty) projects page under the work/edu tab!
					</NewsDescText>

					<NewsDescText>
						if you're reading this on mobile, you're not! in fact you can't! because the site looks terrible on mobile. one of the next changes
						to the website will be making it reponsive to mobile screens! because right now, it looks terrible!
					</NewsDescText>

				</NewsDescContainer>



			</NewsContainer>
		</div>
	)
}

export default Meta;