import React from 'react';
import { FooterContainer, FooterTitle, FooterTitle2, LayoutButton, LayoutButtonLink } from '../Components/Layout.Styles.js';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<FooterContainer>
			<FooterTitle>
				made by me! during spring break.
			</FooterTitle>

			<FooterTitle2>
				powered by <Link to={"https://en.wikipedia.org/wiki/Red_Bull"} className="footerLink"> this stuff </Link> #notanad
			</FooterTitle2>

			<div className="layoutButtons">
			<LayoutButtonLink to={"https://github.com/magg-le"}>
					<LayoutButton>
						github
					</LayoutButton>
			</LayoutButtonLink>

			<LayoutButtonLink to={"https://www.linkedin.com/in/michael-lott-5522b0252/"}>
					<LayoutButton>
						linkedin
					</LayoutButton>
			</LayoutButtonLink>

			<LayoutButtonLink to={"/lol"}>
					<LayoutButton>
						error page!
					</LayoutButton>
			</LayoutButtonLink>
			</div>

		</FooterContainer>
	)
}

export default Footer;