import Styled from 'styled-components';
import pattern from '../Assets/pattern1.svg';
import {Link} from 'react-router-dom';

export const ErrorContainer = Styled.div`
	background-color: ${props => props.theme.main.background};
	background-image: url(${pattern});
	background-size: contain;
	background-repeat: repeat;
	height: 100vh;
	width: 100vw;
	justify-content: center;
	display: flex;
`

export const ErrorImg = Styled.img`
	box-shadow: 0 0 1rem 0rem ${props => props.theme.main.tertiary};
	height: 20vw;
	margin: 0 auto 0 auto; 
	display: flex;
`

export const ErrorHeader = Styled.h1`
	margin: 8vh auto 0 auto;
	text-align: center;
	color: ${props => props.theme.main.primary};
	font-weight: 600;
	font-size: 2vw;
`

export const ErrorSubheader = Styled.h2`
	margin: 1rem auto 2rem auto;
	text-align: center;
	color: ${props => props.theme.main.primary};
	font-weight: 600;
	font-size: 1.5vw;
`

export const ErrorCaptionContainer = Styled.div`
    padding: 2vw 3vw;
    background-color: ${props => props.theme.main.primary};
    margin: 2rem auto auto auto;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    position: relative;
  	right: 1rem;
`

export const ErrorCaption = Styled.p`
	color: ${props => props.theme.main.text};
	font-size: 1.25vw;
	text-align: center;
	margin-bottom: 0;
	line-height: 1.5vw;
`

export const ErrorButtonLink = Styled(Link)`
	height: auto;
	display: inline-block;
	width: auto;
	margin: 3rem auto auto auto;
`

export const ErrorButton = Styled.button`
	font-weight: 500;
	font-size: 1.25vw;
	height: 4vw;
	width: 10vw;
	background-color: ${props => props.theme.main.primary};
	color: ${props => props.theme.main.text};
`