import React from 'react';
import { Routes, Route, Outlet }  from 'react-router-dom';
// Layout
import Layout from './Layout/Layout.jsx';

// Pages
import Home from './Pages/Home.jsx';
import Error from './Pages/Error.jsx';
import Resume from './Pages/Resume.jsx';
import Personal from './Pages/Personal.jsx';
import CS131 from './Pages/CS131.jsx';
import Meta from './Pages/Meta.jsx'

const App = () => {
  return (
    <div className="App">
      <Routes>

          <Route path="*" element={<Error />} />

          <Route element={<Layout> <Outlet/> </Layout>}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/personal" element={<Personal />}/> 
            <Route exact path="/resume" element={<Resume />}/>
            <Route exact path="/cs131" element={<CS131 />} />
            <Route exact path="/meta" element={<Meta />}/> 
          </Route>
       </Routes>
    </div>
  );
}

export default App; 