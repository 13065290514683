import Styled from "styled-components";
import pattern from "../Assets/pattern1.svg";

export const HomeContainer = Styled.div`
    background-color: ${props => props.theme.main.background};
    background-image: url(${pattern});
	background-size: contain;
	background-repeat: repeat;
	height: 100vh;
    justify-content: center;
    padding: 0vh 15vw 0vh 15vw;
    display: flex;
    margin-top: 3vh;
    width: 100vw;
`

export const BlurbContainer = Styled.div`
    padding: 2rem 3rem;
    background-color: ${props => props.theme.main.primary};
    width: min-content;
    height: auto;
    margin: auto 10rem auto 0;
    box-shadow: -2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    width: 100vw;
`

export const BlurbText = Styled.p`
    font-size: 1.25vw;
    margin-bottom: 1.5rem;
    line-height: 1.5vw;
    color: ${props => props.theme.main.text};
`

export const BlurbHeader = Styled.h1`
    font-size: 2.5vw;
    margin: 1rem auto 2rem;
    color: ${props => props.theme.main.text};
`

export const BlurbImg = Styled.img`
    height: 25vw;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    margin: auto 0;
    user-drag: none;
`