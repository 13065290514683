import Styled from 'styled-components';
import pattern from '../Assets/pattern1.svg';

export const MemoriesContainer = Styled.div`
	background-image: url(${pattern});
	background-color: ${props => props.theme.main.background};
	background-size: contain;
	background-repeat: repeat;
	height: auto;
    justify-content: center;
    padding: 10rem 15rem 35rem 15rem;
    display: flex;
    width: 100vw;
`

export const MemoriesImg = Styled.img`
    max-width: 20vw;
    max-height: 20vw;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    margin-top: 5vh;
    display: flex;
    user-drag: none;
`

export const MemoriesDescContainer = Styled.div`
    padding: 2rem 3rem;
    background-color: ${props => props.theme.main.primary};
    height: auto;
    width: min-content;
    white-space: nowrap;
    margin: 5vw auto;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    position: relative;
`

export const MemoriesDescText = Styled.p`
    font-size: 1.25vw;
    margin-bottom: 1.5rem;
    line-height: 1.5vw;
    color: ${props => props.theme.main.text};
`

export const MemoriesDescHeader = Styled.h1`
    font-size: 2.5vw;
    margin: 1rem auto 2rem;
    color: ${props => props.theme.main.text};
`

export const ImageDiv = Styled.div`
    text-align: center;
    max-height: 38rem;
    margin: 2vh 0vh;
`
