import Styled from 'styled-components';
import pattern from "../Assets/pattern1.svg";

export const MetaContainer = Styled.div`
    background-color: ${props => props.theme.main.background};
    background-image: url(${pattern});
	background-size: contain;
	background-repeat: repeat;
    padding-bottom: 2rem;
    height: 92vh;
    justify-content: center;
    padding: 0 15vw 8vh 15vw;
    display: flex;
    margin-top: 8vh;
    width: 100vw;
`

export const MetaImg = Styled.img`
    height: 25vw;
    box-shadow: 2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    margin: auto 0;
    user-drag: none;
`

export const MetaDescContainer = Styled.div`
    padding: 2rem 3rem;
    background-color: ${props => props.theme.main.primary};
    width: auto;
    height: auto;
    margin: auto 10rem auto 0rem;
    box-shadow: -2rem 2rem 0 0 ${props => props.theme.main.tertiary};
    position: relative;
`


export const MetaDescText = Styled.p`
    font-size: 1.25vw;
    margin-bottom: 1.5rem;
    line-height: 2.75rem;
    color: ${props => props.theme.main.text};`

export const MetaDescHeader = Styled.h1`
    font-size: 2.5vw;
    margin: 1rem auto 2rem;
    color: ${props => props.theme.main.text};
`