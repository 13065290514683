import React from 'react';
import { ErrorContainer, ErrorImg, ErrorHeader, ErrorSubheader, ErrorCaption, ErrorCaptionContainer, ErrorButtonLink, ErrorButton } from '../Components/Error.Styles.js';
import errorImg from '../Assets/point-and-laugh.jpg';

const Error = () => {
	return (
			<ErrorContainer>
				<div className="container-md">
					<ErrorHeader>
						error404! 
					</ErrorHeader>

					<ErrorSubheader>
						awkward...
					</ErrorSubheader>

					<ErrorImg src={errorImg} />

					<ErrorCaptionContainer>
						<ErrorCaption>
							silly you!! that's not a valid url. <br/>
							great. now they're laughing at you. <br/>
							quick, hit that button!!
						</ErrorCaption>
					</ErrorCaptionContainer>

					<div className="errorButtons">
					<ErrorButtonLink to={"/"}>
						<ErrorButton>
							hurry! click me!!
						</ErrorButton>
					</ErrorButtonLink>

					<ErrorButtonLink to={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}>
						<ErrorButton>
							...or is it me??
						</ErrorButton>
					</ErrorButtonLink>
					</div>

				</div>
			</ErrorContainer>
	)
}

export default Error;